<template>
  <v-container fluid style="height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'customers'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h1> {{ $t('customer.edit.title') }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-auto"
            elevation="2"
            color="success"
            medium
            @click="customerEdit"
        >
          <v-icon size="28">mdi-content-save-outline</v-icon>
<!--          {{ $t('customer.buttons.save') }}-->
        </v-btn>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-1"
            elevation="2"
            color="red"
            dark
            medium
            @click="dialog = true"
        >
          <v-icon size="28">mdi-trash-can-outline</v-icon>
<!--          {{ $t('customer.buttons.delete') }}-->
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8" md="8">
        <v-card>
          <v-card-title>
            {{ $t('customer.titles.personal_data') }}
          </v-card-title>
          <v-card-text>
            <v-form class="pa-4">
              <v-row>
                <v-col cols="12" md="4">
                  <v-select v-model="form.gender" :label="$t('customer.gender.title')" :items="gender_select" outlined hide-details :append-icon="'mdi-chevron-down'" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.first_name" outlined hide-details :label="$t('customer.first_name')" :placeholder="$t('customer.first_name')" required :error="errors.first_name!==undefined"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.last_name" outlined hide-details :label="$t('customer.last_name')" :placeholder="$t('customer.last_name')" required :error="errors.last_name!==undefined"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.company_name" outlined hide-details :label="$t('customer.company_name')" :placeholder="$t('customer.company_name')"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.email" outlined hide-details type="email" :label="$t('customer.email')" :placeholder="$t('customer.email')" required :error="errors.email!==undefined"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4">
        <v-card class="mb-6">
          <v-card-title>{{ $t('customer.titles.settings') }}</v-card-title>
          <v-card-text>
<!--            <v-switch-->
<!--                v-model="form.email_send"-->
<!--                :label="$t('customer.email_send')"-->
<!--                color="success"-->
<!--            ></v-switch>-->
            <v-switch
                v-model="form.is_deleted"
                :label="$t('customer.is_deleted')"
                color="success"
            ></v-switch>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>{{ $t('customer.titles.avatar') }}</v-card-title>
          <v-card-text class="d-flex align-center">
            <v-avatar size="96">
              <v-icon size="96">mdi-account</v-icon>
            </v-avatar>
            <v-btn class="mt-1 ml-4">
              {{ $t('customer.buttons.avatar_create') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="500"
      >
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center">
              {{ $t('customer.titles.customer_delete') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="grey" dark @click="dialog = false">
              {{ $t('customer.buttons.abort') }}
            </v-btn>
            <v-btn color="red" dark @click="customerDelete">
              {{ $t('customer.buttons.delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      preloader: true,
      dialog: false,
      form: {
        identification: this.$t('customer.create.identification'),
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        birthday: '',
        gender: 'female',
        is_deleted: 0,
        email_send: 0,
      },
      gender_select: [
        {
          text: this.$t('customer.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('customer.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('customer.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('customer.gender.indefinite'),
          value: 'indefinite'
        },
      ],
      errors: {},
    }
  },
  mounted() {
    this.$store.dispatch('customerEdit', this.$route.params.id).then(() => {
      this.form = this.$store.getters.customer
      this.preloader = false
    })
  },
  methods: {
    getPassword(password) {
      this.form.password = password
    },
    customerEdit() {
      this.$store.dispatch('customerUpdate', this.form).then(() => {
        this.$router.replace({
          name: 'customers'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    },
    customerDelete() {
      this.$store.dispatch('customerDelete', this.form.id).then(() => {
        this.$router.replace({
          name: 'customers'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    }
  }
}
</script>
